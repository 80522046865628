@import '../../styles/vars.scss';

.commerceOrdersWidgets {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  // Create more solid selector
  & > &_widget {
    flex-basis: 15%;
    flex-shrink: 0;
    flex-grow: 1;
    margin-right: $margin-1;
    border-bottom: 3px transparent solid;

    &--active {
      border-bottom: 3px use-color('primary') solid !important;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
