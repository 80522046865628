@import '../../styles/vars.scss';

.matchScore {
  &_info {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &_team {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    word-break: keep-all;
    white-space: nowrap;
    width: 45%;

    &--reverse {
      flex-direction: row-reverse;
    }
  }

  &_teamLogo {
    display: block;
    height: 48px;
    width: 48px;
    margin: 0 $margin-1;
  }

  &_score {
    width: 10%;
    word-break: keep-all;
    white-space: nowrap;
    font-size: 18px;
    text-align: center;
    vertical-align: middle !important;
  }

  &_date {
    padding-top: 5px;
    word-break: keep-all;
    white-space: nowrap;
    text-align: center;
  }
}
