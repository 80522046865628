.uDrawer_checkboxes {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
}

.uDrawer_addControls {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
}

.uDrawer_addAction {
  flex-shrink: 2;
  margin-left: 20px;
}

.uDrawer_resetAction {
  margin-bottom: 40px !important;
}
.uDrawer_resetAction button {
  display: block;
  width: 100%;
}

.uDrawer .ant-form-item-label {
  display: block;
  line-height: unset;
}
.uDrawer .ant-form-item-label:after {
  display: none;
}

.uDrawer_label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.uDrawer_control {
  width: 100%;
  margin-bottom: 10px;
}
