/**
  * DO NOT USE THEME NAMES IN HERE! (like themeProvider--BLACK)
  * In this file we only set references of ant-design elements to color in theme
  Example:

  // Correct
  .ant-divider { background-color: use-color('contrast-3'); }

  // Incorrect
  .themeProvider--BLACK {
    .ant-divider { background-color: white }
  }

  .themeProvider--WHITE {
    .ant-divider { background-color: black }
  }

  If you want to define specific rules for specific theme
  Use themeProvider.scss and mixins like @include use-black-theme();
 */

@import 'vars.scss';

.themeProvider {
  .ant-table-small {
    border: 0 !important;
  }

  .ant-upload {
    width: 100%;
  }

  .ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
    color: use-color('contrast-1');
  }

  .ant-modal-confirm-body .ant-modal-confirm-title {
    color: use-color('contrast-1');
  }

  .ant-descriptions {
    &-title {
      color: use-color('contrast-1');
    }

    &-item-label {
      color: use-color('contrast-2');
    }

    &-item-content {
      color: use-color('contrast-1');
    }

    &-bordered {
      .ant-descriptions-item-label {
        background-color: use-color('main-1');
      }
      .ant-descriptions-view {
        border: 1px use-color('main-1') solid;
      }
      .ant-descriptions-row {
        border-bottom: 1px use-color('main-1') solid;

        &:last-child {
          border-bottom: none;
        }
      }
      .ant-descriptions-item-label,
      .ant-descriptions-item-content {
        border-right: 1px use-color('main-1') solid;

        &:last-child {
          border-right: none;
        }
      }
    }
  }

  .ant-modal {
    &-body {
      background-color: use-color('main-2');
      color: use-color('contrast-1');
    }

    &-content {
      background-color: use-color('main-2');
    }

    &-close {
      color: use-color('contrast-1');
    }

    &-header {
      background: use-color('main-2');
      border-bottom-color: use-color('contrast-3');
    }

    &-title {
      color: use-color('contrast-1');
    }
  }

  .ant-tabs-bar {
    border-bottom-color: use-color('main-1');
  }

  .ant-tabs-tab {
    color: use-color('contrast-1');

    &-active {
      // Remove highlight of active tab
      color: use-color('contrast-1');
    }
  }

  .ant-checkbox-wrapper {
    color: use-color('contrast-1');
  }

  .ant-select {
    color: use-color('contrast-1');
  }

  .ant-divider {
    background-color: use-color('contrast-3');

    &:before,
    &:after {
      border-color: use-color('contrast-3');
    }

    &-with-text-center {
      background-color: transparent;
    }

    &-inner-text {
      color: use-color('contrast-1');
    }
  }

  .ant-dropdown-menu {
    background-color: use-color('main-2');
    color: use-color('contrast-1');
  }

  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    color: use-color('contrast-1');
  }

  .ant-menu-submenu-popup {
    background-color: use-color('main-2');
  }

  .ant-checkbox-inner {
    background-color: use-color('main-2');
    border-color: use-color('contrast-3');
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: use-color('contrast-3');
  }

  .ant-table-body {
    // &::-webkit-scrollbar {
    //   height: 0 !important;
    //   width: 0 !important;
    //   overflow: hidden;
    // }
  }

  .ant-table-placeholder {
    background-color: use-color('main-2');
  }

  ::placeholder {
    color: use-color('contrast-3');
  }

  .ant-empty-description {
    color: use-color('contrast-3');
  }

  .ant-input {
    background-color: use-color('main-2');

    color: use-color('contrast-1');
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid;
    border-color: use-color('contrast-3');
    &[disabled] {
      opacity: 0.5;

      &:hover {
        border-color: use-color('contrast-3');
      }
    }
  }

  .ant-btn {
    background-color: use-color('main-3');
    color: use-color('contrast-1');
    border: none;
    box-shadow: $shadow-1;

    $disabledOpacity: 0.4;

    &-primary {
      background-color: use-color('primary');
      color: use-color('main-2');

      &[disabled] {
        opacity: $disabledOpacity;

        &:hover {
          background-color: use-color('primary');
          color: use-color('main-2');
        }
      }
    }

    &-default {
      background-color: use-color('main-3');
      color: use-color('contrast-1');

      &[disabled] {
        opacity: $disabledOpacity;

        &:hover {
          background-color: use-color('main-3');
          color: use-color('contrast-1');
        }
      }
    }

    &-danger {
      background-color: use-color('main-3');
      color: use-color('danger');
      border-color: use-color('main-1');

      &[disabled] {
        opacity: $disabledOpacity;

        &:hover {
          background-color: use-color('main-3');
          color: use-color('danger');
          border-color: use-color('main-1');
        }
      }
    }

    &[disabled] {
      opacity: $disabledOpacity;

      &:hover {
        background-color: use-color('main-3');
        color: use-color('contrast-1');
        border: none;
        box-shadow: $shadow-1;
      }
    }
  }

  .ant-btn-primary {
    background-color: use-color('primary');
    color: use-color('main-2');
  }

  .ant-table {
    background-color: use-color('main-2');
    color: use-color('contrast-1');
  }

  .ant-tabs {
    color: use-color('contrast-1');
  }

  .ant-table-footer {
    background-color: use-color('main-2');
    color: use-color('contrast-1');
    border-top: 1px use-color('main-1') solid;

    &:before {
      display: none;
    }
  }

  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    border-bottom: 1px use-color('main-1') solid;
    vertical-align: top;
  }

  .ant-table-thead > tr > th {
    border-right: 1px use-color('main-1') solid;
    //background-color: use-color('main-2');
    color: use-color('contrast-3');
    white-space: nowrap;

    &:last-child {
      border-right: none;
    }
  }

  .ant-table-thead > tr,
  .ant-table-tbody > tr {
    background-color: use-color('main-2');
  }

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
  .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
  .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background-color: use-color('main-1');
  }

  // Styles of selected rows in table
  .ant-table-thead,
  .ant-table-tbody {
    .ant-table-row-selected > td {
      background-color: use-color('main-1') !important;
    }
  }

  // Filters icon in table head
  .ant-table-thead > tr > th .anticon-filter,
  .ant-table-thead > tr > th .ant-table-filter-icon {
    color: use-color('contrast-3');

    &.ant-table-filter-selected {
      color: use-color('primary');
    }
  }

  .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .anticon-filter,
  .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .ant-table-filter-icon {
    background-color: use-color('main-2');
  }

  .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .anticon-filter:hover,
  .ant-table-thead
    > tr
    > th.ant-table-column-has-actions.ant-table-column-has-filters:hover
    .ant-table-filter-icon:hover {
    background-color: use-color('main-2');
    color: use-color('contrast-1');
  }

  // Table head sort icons
  .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner {
    color: use-color('contrast-3');
  }

  .ant-menu {
    background-color: use-color('main-3');
  }

  .ant-menu-item:active,
  .ant-menu-submenu-title:active {
    background-color: use-color('main-1');
  }

  .ant-dropdown-menu-item:active,
  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-item:focus,
  .ant-dropdown-menu-submenu-title:active,
  .ant-dropdown-menu-submenu-title:hover,
  .ant-dropdown-menu-submenu-title:focus {
    background-color: use-color('main-1');
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: use-color('main-1');
  }

  .ant-table-small {
    border: 1px use-color('main-1') solid;
  }

  .ant-form-item {
    color: use-color('contrast-1');
  }

  .ant-form-item-label {
    line-height: 1.5;
    margin-bottom: 0;
    overflow: visible;
  }

  .ant-form-item-label > label {
    color: use-color('contrast-2');
    position: relative;
  }

  .ant-form-item-label > label::after {
    margin-left: 0;
  }

  .ant-select-selection {
    background-color: use-color('main-2');
    border-color: use-color('contrast-3');
    color: use-color('contrast-1');
    border-radius: 0;
    border-top: none;
    border-right: none;
    border-left: none;
    padding-left: 0;
  }

  .ant-form-item-control,
  .uDrawer_control {
    $formControlBorderColor: use-color('contrast-3');
    line-height: 1;

    // Remove borders and border-radius to make it looks like in material design
    .ant-input-number, // InputNumber
    .ant-input, // TextArea
    .ant-select-selection, // Select
    input {
      // Input
      background-color: use-color('main-2');
      border-color: $formControlBorderColor;
      color: use-color('contrast-1');
      border-radius: 0;
      border-top: none;
      border-right: none;
      border-left: none;
      padding-left: 0;
    }

    .ant-select-selection {
      background-color: unset;

      &__rendered {
        margin-left: 0; // Remove left padding in select
      }
    }
  }

  .ant-select-arrow .ant-select-arrow-icon svg {
    fill: use-color('contrast-2');
  }

  .ant-select-dropdown {
    background-color: use-color('main-2');

    &-menu-item {
      color: use-color('contrast-1');

      &-active,
      &-selected {
        background-color: use-color('main-3');

        &:hover,
        &:focus,
        &:active {
          background-color: use-color('main-3');
        }
      }
    }
  }

  .ant-statistic-content {
    color: use-color('contrast-1');
  }

  .ant-statistic-title {
    color: use-color('contrast-1');
    opacity: 0.45;
  }

  .ant-tabs-bar {
    margin-bottom: 25px;
  }

  .ant-switch {
    background-color: use-color('main-3');
    border-color: use-color('main-1');

    &-checked {
      background-color: use-color('contrast-3');
      color: use-color('main-1');
    }

    &-inner {
      color: use-color('contrast-2');
    }
  }

  .ant-tag,
  .ant-tag a,
  .ant-tag a:hover,
  .ant-select-selection__choice {
    color: use-color('contrast-1');
    background-color: use-color('contrast-3');
    border-color: transparent;
  }

  .ant-tag-has-color,
  .ant-tag-has-color a,
  .ant-tag-has-color a:hover {
    color: use-color('main-2');
  }

  .ant-skeleton-content {
    .ant-skeleton-title,
    .ant-skeleton-paragraph > li {
      background-color: use-color('main-3');
    }
  }

  .ant-tabs-ink-bar {
    background-color: use-color('primary');
  }

  .ant-pagination {
    color: use-color('contrast-2');

    &-item {
      color: use-color('contrast-2');
      background-color: use-color('main-2');
      border-color: use-color('contrast-3');

      &-disabled {
        background-color: use-color('contrast-3');
      }

      &-active {
        border-color: use-color('primary');
        background-color: use-color('main-2');
      }

      &-ellipsis {
        color: use-color('contrast-2') !important;
      }

      a {
        color: inherit;
      }
    }

    &-prev,
    &-next {
      background-color: transparent;
      border-color: use-color('contrast-3');
      color: use-color('contrast-3');

      // Disabled table pagination
      .ant-pagination-item-link {
        background-color: transparent;
        border-color: use-color('contrast-3');
        color: use-color('contrast-3');
      }

      a {
        color: use-color('contrast-2');
      }
    }
  }

  .ant-drawer-content {
    background-color: use-color('main-2');
  }

  .ant-alert {
    &-error {
      background-color: use-color('main-3');
      border-color: use-color('danger');
    }

    &-message,
    &-description {
      color: use-color('contrast-1');
    }
  }

  .ant-fullcalendar {
    color: use-color('contrast-1');

    &-value {
      color: use-color('contrast-2') !important;
    }

    &-date,
    &-month {
      color: use-color('contrast-1') !important;
      border-top: 1px solid use-color('contrast-3');

      &:hover {
        background-color: use-color('main-1');
      }
    }

    &-selected-day {
      .ant-fullcalendar-date {
        background-color: use-color('main-1');
      }
    }

    &-today {
      .ant-fullcalendar-date,
      .ant-fullcalendar-month {
        border-top: 1px solid use-color('primary');
      }
    }

    &-next-month-btn-day,
    &-last-month-cell {
      opacity: 0.5;
    }

    &-month-panel-current-cell {
      .ant-fullcalendar-month {
        background-color: use-color('main-1');
        border-top: 1px solid use-color('primary');
      }
    }
  }

  .ant-badge-status-text {
    color: use-color('contrast-1');
  }

  .ant-radio {
    &-group {
      color: use-color('contrast-1');
    }

    &-button-wrapper {
      color: use-color('contrast-1');
      background: use-color('main-3');
      border: 1px transparent solid;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);

      &-checked {
        border-color: use-color('primary');
      }

      &:not(:first-child)::before {
        background-color: use-color('main-1');
      }
    }

    &-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
      border-color: use-color('primary');
      color: use-color('contrast-1');
      background: use-color('main-3');
    }
  }

  .ant-upload-list-picture .ant-upload-list-item,
  .ant-upload-list-picture-card .ant-upload-list-item {
    border: 1px use-color('contrast-3') solid;
  }

  .ant-upload-list-picture .ant-upload-list-item-name,
  .ant-upload-list-picture-card .ant-upload-list-item-name {
    color: use-color('contrast-1');
  }

  .ant-upload-list-item-card-actions .anticon {
    color: use-color('contrast-1');
  }

  .ant-select-selection {
    &--single {
      min-height: 30px;
      height: auto;
    }
  }

  // FIx clearfix in select renderer
  // .ant-select-selection__rendered::after {
  //   //display: block;
  //   //clear: both;
  //   //height: 0;
  // }

  .has-error .ant-input:not([disabled]) {
    border-color: use-color('danger');

    &:hover {
      border-color: use-color('danger');
    }
  }

  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: none;
  }

  .uTable {
    &_errorOverlay {
      background-color: use-color('main-3');
      opacity: 0.75;
    }

    &_container {
      background-color: use-color('main-2');
    }
  }
}
