.uUpload_previewImg {
  width: auto;
  max-width: 100%;
  max-height: 70vh;
  margin: 20px auto 0;
  display: block;
}

.uUpload_previewModal {
  width: fit-content !important;
  max-width: 80vw;
}
.uUpload_previewModal .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-left: 0;
}
.uUpload_previewModal .ant-modal-confirm-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
