@import '../../styles/vars.scss';

.privateLayout {
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  padding: 0;

  &_sidebar {
    height: 100%;
    position: relative;
    z-index: 2;
    box-shadow: var(--box-shadow);
  }

  &_content {
    height: 100%;
    overflow: auto;
    flex-grow: 1;
    z-index: 1;
  }
}
