.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.loader_text {
  margin-top: 10px;
}
