@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.panel {
  background: var(--color-main-2, #fcfcfc);
  border-radius: 3px;
  border: none;
  padding: 15px;
  box-shadow: 8px 8px 15px rgba(0, 0, 0, 0.1);
  margin: 0 auto 20px;
  z-index: 50;
  opacity: 0.99;
  color: var(--color-contrast-1, #727272);
}
.panel--inline {
  display: inline-block;
}
.panel .ant-card-body {
  padding: 0;
}
.panel .ant-card-head {
  border-bottom: none;
  padding: 0;
  border-radius: unset;
  margin-bottom: 0;
  min-height: unset;
  font-size: 16px;
  color: unset;
  font-weight: unset;
}
.panel .ant-card-head-title {
  padding: 0;
}
