@import '../../styles/vars.scss';

.commerceShopPage {
  &_description {
    margin-bottom: 20px;
  }

  &_create_action_container {
    margin-right: 15px;
  }
}
