// Colors:
// SCSS variables along with CSS variables is necessary so we can use
// a SCSS var in fallback usage of CSS var. Example:
// color: var(--color-main, $map-get($WHITE_THEME_COLORS, 'color-main'))
$BLACK_THEME_COLORS: (
  color-vibrant-contrast: #fee6c0,
  color-main-1: #1b2129,
  color-main-2: #222831,
  color-main-3: #353e4b,
  color-contrast-1: #fee6c0,
  color-contrast-2: #fee0a6,
  color-contrast-3: #c8a474,
  color-danger: #a9040d,
  color-success: #567534,
  color-warning: #bf8328,
  color-primary: #317cba,
  color-shadow: rgba(0, 0, 0, 0.1),
  color-sidebar: #1c293b,
  color-label: #c4c4c4,
);

$WHITE_THEME_COLORS: (
  color-vibrant-contrast: #ffffff,
  color-main-1: #f1f3f8,
  color-main-2: #ffffff,
  color-main-3: #ffffff,
  color-contrast-1: #000000,
  color-contrast-2: #777777,
  color-contrast-3: #d9d9d9,
  color-danger: #ef000d,
  color-success: #7ed321,
  color-warning: #ea9f2e,
  color-primary: #40a9ff,
  color-shadow: rgba(0, 0, 0, 0.1),
  color-sidebar: #0a1a3b,
  color-label: #c4c4c4,
);

$DEFAULT_THEME_COLORS: $WHITE_THEME_COLORS;

$shadow-1: 0 2px 6px 0 map-get($DEFAULT_THEME_COLORS, 'color-shadow'); // Тень маленькая, для кнопок
$shadow-2: 4px 4px 10px 0 map-get($DEFAULT_THEME_COLORS, 'color-shadow'); // Тень большая, для блоков

$padding-1: 10px; // Паддинг элементов внутри блоков
$padding-2: 20px; // Паддинг блоков
$padding-3: 30px;

$margin-1: 10px;
$margin-2: 20px;
$margin-3: 30px;

// Font weight
$WEIGHT_BOLD: 700;
$WEIGHT_NORMAL: 400;
$WEIGHT_THIN: 200;

// Heights
$HEIGHT_HEADER: 80px;

// Padding, margins, gutters, gaps
$PADDING_PAGE_CONTENT: 20px;

// Animations
$EASING: ease;
$DURATION: 0.2s;

// Breakpoints
$XS: 480px;
$SM: 576px;
$MD: 768px;
$LG: 992px;
$XL: 1200px;
$XXL: 1600px;

// Media queries
$BREAK_XS: 'only screen and (max-width: #{$XS})';
$BREAK_SM: 'only screen and (max-width: #{$SM})';
$BREAK_MD: 'only screen and (max-width: #{$MD})';
$BREAK_LG: 'only screen and (max-width: #{$LG})';
$BREAK_XL: 'only screen and (max-width: #{$XL})';
$BREAK_XXL: 'only screen and (max-width: #{$XXL})';

@function use-color($colorName) {
  @return var(--color-#{$colorName}, map-get($DEFAULT_THEME_COLORS, 'color-#{$colorName}'));
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@mixin fade-in {
  animation-name: FadeIn;
  animation-duration: $DURATION * 2;
  animation-timing-function: $EASING;
}
