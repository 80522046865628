@import '../../styles/vars.scss';

.panel {
  background: var(--color-main-2, map-get($DEFAULT_THEME_COLORS, 'color-main-2'));
  border-radius: 3px;
  border: none;
  padding: 15px;
  box-shadow: 8px 8px 15px rgba(0, 0, 0, 0.1);
  margin: 0 auto 20px;
  z-index: 50;
  opacity: 0.99;
  color: var(--color-contrast-1, map-get($DEFAULT_THEME_COLORS, 'color-contrast-1'));
  @include fade-in();

  &--inline {
    display: inline-block;
  }

  &--no-shadow {
    box-shadow: none;
  }

  &--fullHeight {
    height: calc(100% - 20px);
  }

  &--noMargin {
    margin-bottom: 0;
  }

  &--noPadding {
    padding: 0;
  }

  .ant-card-body {
    padding: 0;
  }

  .ant-card-extra {
    display: flex;

    & > * {
      margin-right: $margin-1;
    }

    & > *:last-child {
      margin-right: 0;
    }
  }

  .ant-card-head {
    border-bottom: none;
    padding: 0;
    border-radius: unset;
    margin-bottom: 0;
    min-height: unset;
    font-size: 16px;
    color: unset;
    font-weight: unset;

    &-title {
      h2 {
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: keep-all;
        white-space: nowrap;
      }
    }
  }

  .ant-card-body {
    height: 100%;
  }

  .ant-card-head-title {
    padding: 0;
  }
}
