.uTable_wrapper {
  position: relative;
}

.uTable_checkboxTotal {
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.uTable_errorModal {
  min-width: 30vw;
  max-height: 80%;
  max-width: 80vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
  z-index: 100;
}

.uTable_errorOverlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 100;
}

.uTable_errorActions {
  margin-top: 20px;
}

.uTable_customFilter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
}

.uTable_pagination {
  margin-left: auto;
}

.uTable_search {
  margin-bottom: 0;
}

.uTable_search .ant-input {
  border: none;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1) !important;
}

.uTable_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.uTable_headerActions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.uTable_container {
  padding: 10px;
  box-shadow: 8px 8px 15px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.uTable_container--no-shadow {
  box-shadow: none;
}

.uTable_title {
  font-size: 24px;
  margin-bottom: 0;
}

.uTable_footerAction {
  margin-right: 10px;
}

.uTable_action {
  margin-left: 10px;
}
@media only screen and (max-width: 480px) {
  .uTable_footerAction {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0;
  }
}
@media only screen and (max-width: 480px) {
  .uTable_footerAction .ant-pagination-total-text {
    display: block;
    text-align: center;
  }
}
