@import '../../styles/vars.scss';

.page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  width: 100%;
  overflow: hidden;
  background-color: var(--color-main-1, map-get($DEFAULT_THEME_COLORS, 'color-main-1'));
  @include fade-in();
}

.pageContent {
  height: auto;
  flex-grow: 2;
  padding: $PADDING_PAGE_CONTENT;
  overflow: auto;
  position: relative;
}
