@import '../../styles/vars.scss';

$circleMargin: 20px;

$logoSize: 42px;
$logoBorderSize: 1px;
$logoFullHeight: $HEIGHT_HEADER;

$avatarSize: 42px;
$avatarPadding: 10px;
$avatarBorderSize: 1px;
$avatarFullHeight: $avatarSize + ($avatarPadding * 2) + $avatarBorderSize;

.sidebar {
  height: 100%;
  background: use-color('sidebar');
  position: static;
  z-index: 2;

  &::-webkit-scrollbar {
    height: 0 !important;
    width: 0 !important;
  }

  &--mobile.sidebar--collapsed {
    overflow: hidden;
    width: 0 !important;
    min-width: 0 !important;
    max-width: 0 !important;
    flex: 0 0 0 !important;
  }

  &--mobile.sidebar--open {
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }

  &_menu {
    height: calc(100% - #{$logoFullHeight});
    overflow-y: auto;

    &::-webkit-scrollbar {
      height: 0 !important;
      width: 0 !important;
    }
  }

  &_logo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: $HEIGHT_HEADER;
    margin: 0 auto;
    cursor: pointer;

    img {
      display: block;
      width: $logoSize;
      height: $logoSize;
      border-radius: 50%;
      margin: 0 $circleMargin;
    }
  }

  &_clientName {
    font-size: 32px;
    font-weight: $WEIGHT_BOLD;
  }

  &_trigger {
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 1;
    transform: translateX(50%);
    transition: left $EASING $DURATION;
    background-color: use-color('sidebar') !important;

    .anticon {
      color: use-color('vibrant-contrast');
    }
  }

  &_burger {
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 150;
    display: none;

    @media #{$BREAK_XS} {
      display: block;
    }

    &--right {
      left: unset;
      right: 10px;
    }
  }
}

.ant-menu-inline-collapsed {
  width: 100%;
}

.ant-layout-sider-collapsed {
  .sidebar_logo {
    img {
      margin: 0 auto;
    }
  }

  .sidebar_clientName {
    display: none;
  }

  .sidebar_fullName {
    display: none;
  }

  .sidebar_avatarPhoto {
    margin: 0 auto;
  }
}
