@import '../../styles/vars.scss';

.menu {
  display: flex;
  flex-direction: column;
  border-right: unset;
  overflow-x: hidden;
  background: use-color('sidebar') !important;
  color: use-color('vibrant-contrast');

  &_name {
    padding-top: 5px;
    word-wrap: break-word;
    text-transform: uppercase;
  }

  .ant-menu-item:active,
  .ant-menu-submenu-title:active {
    background-color: use-color('sidebar') !important;
  }

  // Menu item text
  .ant-menu-item {
    background: use-color('sidebar') !important;
    color: use-color('vibrant-contrast') !important;

    & > a {
      background: use-color('sidebar') !important;
      color: use-color('vibrant-contrast') !important;
    }

    &-selected {
      background: use-color('sidebar') !important;
      color: use-color('vibrant-contrast') !important;
    }

    .ant-menu-submenu-title {
      background-color: transparent !important;
    }
  }

  .ant-menu-submenu-selected {
    background-color: transparent !important;

    .ant-menu-submenu-title:active {
      background-color: transparent !important;
    }
  }

  .ant-menu {
    background-color: transparent !important;
    color: use-color('vibrant-contrast');

    // Menu item text
    &-item > a {
      color: use-color('vibrant-contrast');
    }

    &-item-selected {
      background-color: transparent !important;
    }
  }

  &::-webkit-scrollbar {
    height: 0 !important;
    width: 0 !important;
  }

  &_item {
    display: flex !important;
    align-items: center !important;
    width: 100%;
    cursor: pointer;
    text-decoration: none;
    margin-bottom: 15px !important;

    &:hover,
    &:active,
    &:focus {
      .icon_fill {
        fill: #1890ff;
      }
      .icon_stroke {
        stroke: #1890ff;
      }
    }
  }

  .ant-menu-submenu-title {
    height: 100% !important;

    .menu_item {
      margin-bottom: 0 !important;
    }
  }

  &_popup {
    background-color: use-color('sidebar') !important;

    & > .ant-menu {
      background-color: use-color('sidebar') !important;
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: use-color('sidebar') !important;
    }

    .ant-menu-item:active,
    .ant-menu-submenu-title:active {
      background-color: use-color('sidebar') !important;
    }

    .ant-menu-item > a {
      color: use-color('vibrant-contrast');
    }
  }

  &_logout {
    padding: 10px 30px;
    display: inline-block;
  }

  &--opened {
  }

  &--collapsed {
    li.ant-menu-item,
    li.ant-menu-submenu > div.ant-menu-submenu-title {
      height: auto;
      padding: 0 10px !important;
    }

    i.menu_icon {
      font-size: 24px !important;
      line-height: 1 !important;
    }

    span.menu_name {
      display: block !important;
      width: 100%;
      max-width: 100% !important;
      opacity: 1 !important;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 9px !important;
      text-align: center;
      line-height: 1.4;
    }

    .menu_item {
      display: flex !important;
      flex-direction: column !important;
      align-items: center !important;
      width: 100%;
    }

    .ant-menu-submenu-title .menu_item {
      margin-bottom: 15px !important;
    }
  }
}

.ant-tooltip-content {
  .menu_item {
    margin-bottom: 0 !important;
  }

  .menu_name {
    padding-top: 0 !important;
  }
}
