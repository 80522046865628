@import '../../styles/vars.scss';

.systemAsyncTasks {
  &_target {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: default;
    padding: 4px 10px 4px 0;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: $shadow-1;
    color: use-color('contrast-1');

    &:hover,
    &:focus {
      background-color: use-color('main-3');
    }
  }

  &_items {
    &--invalid {
      color: use-color('danger');
    }
  }

  &_targetContent {
    flex-grow: 1;
    padding-right: 10px;
  }

  &_task {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 10px;

    &:hover,
    &:focus {
      background-color: use-color('main-3');
    }
  }

  &_taskIcon {
    margin-right: 10px;
  }

  &_taskTitle {
    flex: 1 0;
    cursor: default;
  }

  &_taskState {
    padding-left: 10px;

    &--NEW {
      color: use-color('contrast-1');
    }

    &--PROCESSING {
      color: use-color('contrast-1');
    }

    &--CANCELLED {
      color: use-color('danger');
    }

    &--FINISHED {
      color: use-color('success');
    }
  }
}
