.querySelect {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.querySelect_card {
  max-width: 250px;
  padding: 0 20px;
}
.querySelect_card--selected {
  background: rgb(219, 244, 255) !important;
}
.querySelect_card--preview {
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
  display: block;
}
.querySelect_tableWrapper {
  padding-top: 30px;
}
.querySelect_linkAction {
  margin-left: 15px;
}
.querySelect_tableAction {
  margin-left: 15px;
}
.querySelect_fetchMoreWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
}
.querySelect_select {
  width: 100%;
}
