.uForm_item--disabled {
  opacity: 0.5;
}

.uForm_error {
  margin-bottom: 20px;
}

.uForm_comment {
  font-size: 12px;
  opacity: 0.7;
  margin-bottom: 10px;
}

.uForm_input {
  width: 100%;
}

.uForm_datePicker {
  width: 100%;
}

.uForm_actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.uForm_actionsBtn {
  margin-right: 15px;
}
.uForm_actionsBtn:last-child {
  margin-right: 0;
}
