@import '../../styles/vars.scss';

.breadCrumbs {
  display: flex;
  flex-direction: row;

  &_separator {
    color: rgba(use-color('contrast-3'), 0.5);
  }
}
