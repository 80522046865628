@import './vars.scss';

// Add some theme specific rules here

@mixin use-black-theme() {
  .ant-tag,
  .ant-tag a,
  .ant-tag a:hover {
    color: use-color('contrast-1');
  }

  .ant-btn-danger {
    color: use-color('contrast-1');
    background-color: use-color('danger');
  }

  .statusTag {
    &--default {
      color: use-color('main-1');
    }

    &--disabled {
      background-color: use-color('main-3') !important;
    }
  }
}
