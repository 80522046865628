@import '../../styles/vars.scss';
@import '../../styles/override.scss';
@import '../../styles/blackTheme.scss';

@mixin set-variables($colorMap) {
  --color-main-1: #{map-get($colorMap, 'color-main-1')};
  --color-main-2: #{map-get($colorMap, 'color-main-2')};
  --color-main-3: #{map-get($colorMap, 'color-main-3')};
  --color-contrast-1: #{map-get($colorMap, 'color-contrast-1')};
  --color-contrast-2: #{map-get($colorMap, 'color-contrast-2')};
  --color-contrast-3: #{map-get($colorMap, 'color-contrast-3')};
  --color-vibrant-contrast: #{map-get($colorMap, 'color-vibrant-contrast')};
  --color-sidebar: #{map-get($colorMap, 'color-sidebar')};
  --color-danger: #{map-get($colorMap, 'color-danger')};
  --color-success: #{map-get($colorMap, 'color-success')};
  --color-warning: #{map-get($colorMap, 'color-warning')};
  --color-primary: #{map-get($colorMap, 'color-primary')};
  --color-shadow: #{map-get($colorMap, 'color-shadow')};
}

.themeProvider {
  background-color: use-color('main-1');
  color: use-color('contrast-1');

  &--WHITE {
    @include set-variables($WHITE_THEME_COLORS);
  }

  &--BLACK {
    @include set-variables($BLACK_THEME_COLORS);
    @include use-black-theme();
  }
}
