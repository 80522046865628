@import '../../styles/vars.scss';

.overlay {
  &_wrapper {
    position: relative;
  }

  &_mask {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: use-color('main-1');
    z-index: 1000;
    cursor: not-allowed;

    &--visible {
      opacity: 0.8;
    }

    &--hidden {
      opacity: 0;
      visibility: hidden;
      cursor: unset;
      display: none;
    }
  }
}
