@import '../../styles/vars.scss';

.inlineLink {
  color: use-color('primary');
  text-decoration: underline;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }

  &:active {
    color: use-color('danger');
  }
}
