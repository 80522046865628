@import '../../styles/vars.scss';

.commerceOrdersTable {
  &_dataRecord {
    display: inline-block;
    margin-right: 10px;

    span {
      color: use-color('contrast-2');
    }
  }

  &_item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  &_itemPreview {
    width: 36px;
    height: 36px;
    overflow: hidden;
    border-radius: 50%;

    img {
      width: 36px;
    }
  }

  &_itemDescription {
    font-size: 85%;
    display: inline-block;
    margin-left: 10px;
    max-width: 280px;
  }
}
