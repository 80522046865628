@import '../../styles/vars.scss';

$avatarSize: 48px;
$circleMargin: 20px;

.header {
  min-height: $HEIGHT_HEADER;
  flex-shrink: 0;
  position: relative;
  padding: 5px $PADDING_PAGE_CONTENT 10px;
  // border-bottom: 1px var(--color-main-2, map-get($DEFAULT_THEME_COLORS, 'color-main-2')) solid;
  z-index: 120;
  // box-shadow: $shadow-2;

  @media #{$BREAK_XS} {
    min-height: 52px; // 32px MenuBtn height + (10px MenuBtn offset * 2)
    padding: 5px 50px 5px 50px; // 50px -- Place for burger btn and actions btn
  }

  &_rightSideContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &_leftSideContainer {
    max-width: 70%;
  }

  &_separator {
    height: 48px;
    margin-left: 30px;
    margin-right: 30px;
    box-shadow: $shadow-1;
  }

  &--dark {
    background: white;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05);
  }

  &_title {
    font-size: 36px;
    font-weight: 800;
    padding: 0;
    margin: 0;
    color: use-color('contrast-1');

    @media #{$BREAK_XS} {
      font-size: 24px;
    }

    &--small {
      font-size: 18px;
    }
    &--dark {
      font-weight: 600;
      // color: white;
      // text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    }
  }
  &_info {
    padding: 10px 0 0 0;
    text-align: center;
  }
  &_actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    padding: 10px 0;

    @media #{$BREAK_XS} {
      padding: 0;
      width: 100%;
    }
  }

  &_drawerAction {
    display: block;
    margin-bottom: 20px;
  }

  &_actionsTrigger {
    @media #{$BREAK_XS} {
      position: fixed;
      top: 10px;
      right: 10px;
    }
  }

  &_action {
    display: inline-block;
    margin: 0 0 0 10px;

    @media #{$BREAK_XS} {
      display: block;
      width: 100%;
      margin: 0 0 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
