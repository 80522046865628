@import './vars.scss';

body {
  margin: 0;
  font-family: Montserrat, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-contrast-1, map-get($DEFAULT_THEME_COLORS, 'color-contrast-1'));
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color-contrast-1, map-get($DEFAULT_THEME_COLORS, 'color-contrast-1'));
  font-weight: $WEIGHT_NORMAL;
}

h2 {
  font-size: 30px;
  font-weight: $WEIGHT_NORMAL !important;
}

h3,
h4 {
  font-size: 24px;
  font-weight: $WEIGHT_NORMAL !important;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  &--center {
    justify-content: center;
    align-items: center;
  }
}

.text {
  &--danger {
    color: use-color('danger');
  }
  &--primary {
    color: use-color('primary');
  }
  &--success {
    color: use-color('success');
  }
  &--warning {
    color: use-color('warning');
  }
  &--muted,
  &--disabled {
    color: use-color('contrast-2');
  }

  &--left {
    text-align: left;
  }
  &--right {
    text-align: right;
  }
  &--center {
    text-align: center;
  }
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.5em;
}

.mb-2 {
  margin-bottom: 1em;
}

.mb-3 {
  margin-bottom: 1.5em;
}

.mb-4 {
  margin-bottom: 2em;
}

.mb-5 {
  margin-bottom: 2.5em;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.5em;
}

.mt-2 {
  margin-top: 1em;
}

.ml-1 {
  margin-left: 0.5em;
}

.ml-2 {
  margin-left: 1em;
}

.ml-3 {
  margin-left: 1.5em;
}

.ml-4 {
  margin-left: 2em;
}

.pl-1 {
  padding-left: 0.5em;
}

.pl-2 {
  padding-left: 1em;
}

.pl-3 {
  padding-left: 1.5em;
}

.pl-4 {
  padding-left: 2em;
}

.pr-1 {
  padding-right: 0.5em;
}

.pr-2 {
  padding-right: 1em;
}

.pr-3 {
  padding-right: 1.5em;
}

.pr-4 {
  padding-right: 2em;
}

.pt-1 {
  padding-top: 0.5em;
}

.pt-2 {
  padding-top: 1em;
}

.pt-3 {
  padding-top: 1.5em;
}

.pt-4 {
  padding-top: 2em;
}

.mr-1 {
  margin-right: 0.5em;
}

.mr-2 {
  margin-right: 1em;
}

.mr-3 {
  margin-right: 1.5em;
}

.mr-4 {
  margin-right: 2em;
}

.d-flex {
  display: flex;
}

.d-block {
  display: block;
}

.flex-wrap {
  flex-wrap: wrap;
}

.w-100 {
  width: 100%;
}

.p-0 {
  padding: 0;
}

.dropdown-button {
  button.ant-btn {
    box-shadow: none;
    border-radius: 0 !important;
  }
}

.ant-table-row td {
  vertical-align: middle !important;
}

.ant-modal-mask {
  background-color: rgba(black, 0.75);
}
