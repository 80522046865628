@import '../../styles/vars.scss';

$circleMargin: 20px;

$logoSize: 42px;
$logoBorderSize: 1px;
$logoFullHeight: $HEIGHT_HEADER;

$avatarSize: 42px;
$avatarPadding: 10px;
$avatarBorderSize: 1px;
$avatarFullHeight: $avatarSize + ($avatarPadding * 2) + $avatarBorderSize;

.avatar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: $avatarPadding 0;
  cursor: pointer;

  .ant-avatar {
    background-color: use-color('contrast-3');
  }

  &_photo {
    display: block;
    min-width: $avatarSize;
    min-height: $avatarSize;
    margin: 0 $circleMargin;
  }

  &_fullName {
    display: block;
    width: calc(100% - (#{$avatarSize} + (#{$circleMargin * 2})));
    padding-right: 20px;
    font-weight: $WEIGHT_BOLD;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
