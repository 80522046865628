@import '../../styles/vars.scss';

.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;

  &_text {
    margin-top: 10px;
    color: use-color('contrast-1');
  }
}
