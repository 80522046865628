@import '../../styles/vars.scss';

.commerceOrder {
  &_addItemBtn {
    color: use-color('contrast-1');

    &--extra {
      margin-right: 15px;
    }

    &--modal {
      margin-top: 35px;
    }
  }

  &_preview {
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }

  .orderInfo {
    font-size: 90%;

    &_content {
      padding: 10px;
      display: flex;
      justify-content: space-between;
    }
    &_label {
      display: inline-block;
      margin-top: 10px;
      color: use-color('contrast-2');
    }
    &_text {
      color: use-color('contrast-1');
    }

    &_printBtn {
      color: use-color('contrast-1');
    }
  }
}

.printerModal {
  &_content {
    display: flex;
    justify-content: space-between;
    margin-bottom: $margin-3;
  }
  &_header {
    font-size: 24px;
    color: use-color('contrast-1');
  }
  &_label {
    color: use-color('contrast-2');
    font-size: 16px;
  }
  &_btn {
    width: 80px;
    height: 30px;
    margin-top: 60px;
  }
  &_select {
    width: 200px;
  }
}

.addItemsModal {
  &_label {
    color: use-color('contrast-2');
  }
  &_quantityInput {
    width: 90px;
  }
  &_quantity {
    margin-top: 25px;
  }
  &_select {
    width: 100%;
  }
}
